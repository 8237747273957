import React, { Suspense, lazy, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_chamasoft/layout";
import { hex2Rgba, rgba2hex } from "../_chamasoft/_helpers";
import * as setupActions from "./modules/Setup/_redux/setupActions";
// import * as utils from "../_chamasoft/_helpers/LocalStorageHelpers";

const Dashboard = lazy(() =>
  import("./modules/Dashboard/Dashboard")
);
const SetupPage = lazy(() =>
  import("./modules/Setup/Setup")
);
const Onboard = lazy(() =>
  import("./modules/Onboard/Onboard")
);
const BranchesPage = lazy(() =>
  import("./modules/Branches/Branches")
);
const AgentsPage = lazy(() =>
  import("./modules/Agents/Agents")
);
const GroupsPage = lazy(() =>
  import("./modules/Groups/Groups")
);
const ProfilePage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const SettingsPage = lazy(() =>
  import("./modules/Settings/SettingsPage")
);
const ReportsPage = lazy(() =>
  import("./modules/Reports/Reports")
);
const SharePage = lazy(() =>
  import("./modules/Share/Share")
);

export default function BasePage() {
  const dispatch = useDispatch();
  const { accessToken } = useSelector(
    (state) => ({
      accessToken: state.auth.authToken,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(setupActions.fetchSystemProfile());
    dispatch(setupActions.fetchSystemTheme());
    dispatch(setupActions.fetchProfiles(accessToken));
    dispatch(setupActions.fetchSystemSettings());
    //dispatch(setupActions.fetchSystemServices());
  }, [dispatch, accessToken]);

  const { fetchingData, theme, fetchingSettings, settings } = useSelector(
    (state) => ({
      fetchingData: state.systemTheme.actionsLoading,
      theme: state.systemTheme.data,
      fetchingSettings: state.systemSettings.actionsLoading,
      settings: state.systemSettings.settings,
    }),
    shallowEqual
  );

  useEffect(() => {
    // console.log('Pantone: ', theme?.pantone);
    // if (theme?.pantone) {
    //   utils.setStorage(
    //     "pantone",
    //     JSON.stringify(theme?.pantone),
    //     (24 * 60 * 60 * 365)
    //   );
    // }
    if (theme?.pantone?.color1 && theme?.pantone?.color1 !== "" && theme?.pantone?.color1 !== "#undefined" && !fetchingSettings && (settings?.disableCustomBranding !== 'true')) {
      document.documentElement.style.setProperty("--cs-primary", hex2Rgba(theme?.pantone?.color1, 1));
      document.documentElement.style.setProperty("--cs-primary-bg", hex2Rgba(theme?.pantone?.color1, 0.08));
      document.documentElement.style.setProperty("--cs-menu-bg", hex2Rgba(theme?.pantone?.color1, 0.18));
      document.documentElement.style.setProperty("--cs-submenu-bg", hex2Rgba(theme?.pantone?.color1, 0.08));
      document.documentElement.style.setProperty("--cs-submenu-bg-hover", hex2Rgba(theme?.pantone?.color1, 0.12));
    } else {
      document.documentElement.style.setProperty("--cs-primary", hex2Rgba('#3699ff', 1));
      document.documentElement.style.setProperty("--cs-primary-bg", hex2Rgba('#eaf2f5', 0.08));
      document.documentElement.style.setProperty("--cs-menu-bg", hex2Rgba('#eaeef3', 0.18));
      document.documentElement.style.setProperty("--cs-submenu-bg", hex2Rgba('#eaeef3', 0.08));
      document.documentElement.style.setProperty("--cs-submenu-bg-hover", hex2Rgba('#f3f6f9', 0.12));
    }

    if (theme?.pantone?.color2 && theme?.pantone?.color2 !== "" && theme?.pantone?.color2 !== "#undefined" && !fetchingSettings && (settings?.disableCustomBranding !== 'true')) {
      document.documentElement.style.setProperty("--cs-secondary", hex2Rgba(theme?.pantone?.color2, 1));
      document.documentElement.style.setProperty("--cs-secondary-lighter", rgba2hex(hex2Rgba(theme?.pantone?.color2, 0.6)));
    } else {
      document.documentElement.style.setProperty("--cs-secondary", 'rgba(45, 87, 106, 1)');
      document.documentElement.style.setProperty("--cs-secondary-lighter", rgba2hex('rgba(76, 152, 187, 1)'));
    }
    // console.log(window.getComputedStyle(document.documentElement).getPropertyValue("--cs-secondary"));
    // console.log(window.getComputedStyle(document.documentElement).getPropertyValue("--cs-secondary-lighter"));
  }, [theme, fetchingSettings, settings.disableCustomBranding]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {fetchingData && <LayoutSplashScreen />}
      {!fetchingData && <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/setup" component={SetupPage} />
        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/onboard" component={Onboard} />
        <ContentRoute path="/groups" component={GroupsPage} />
        <ContentRoute path="/branches" component={BranchesPage} />
        <ContentRoute path="/agents" component={AgentsPage} />
        <ContentRoute path="/profile" component={ProfilePage} />
        <ContentRoute path="/reports" component={ReportsPage} />
        <ContentRoute path="/settings" component={SettingsPage} />
        <ContentRoute path="/share" component={SharePage} />
        <Redirect to="/dashboard" />
        {/* <Redirect to="error/err" /> */}
      </Switch>}
    </Suspense>
  );
}
