import { createSlice } from "@reduxjs/toolkit";

const initialGroupTypesState = {
  actionsLoading: false,
  types: [],
};

const initialCountriesState = {
  actionsLoading: false,
  countries: [],
};

const initialGroupInfoUpdateState = {
  actionsLoading: false,
  group: [],
};

const initialMembersInfoState = {
  actionsLoading: false,
  members: [],
};

const initialGroupContributionsState = {
  actionsLoading: false,
  contributions: [],
  defaults: {},
};

const initialGroupLoansState = {
  actionsLoading: false,
  loans: [],
  defaults: {},
};

const initialGroupAccountsState = {
  actionsLoading: false,
  accounts: [],
  defaults: {},
};

const initialGroupConfirmState = {
  actionsLoading: false,
  defaults: {},
};

const initialBanksState = {
  actionsLoading: false,
  banks: [],
};

const initialBankBranchesState = {
  actionsLoading: false,
  branches: [],
};

export const groupTypesSlice = createSlice({
  name: "groupTypes",
  initialState: initialGroupTypesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    groupTypesFetched: (state, action) => {
      const { service_types } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.types = service_types;
    },
  }
});

export const countriesSlice = createSlice({
  name: "countries",
  initialState: initialCountriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    countriesFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.countries = data;
    },
  }
});

export const groupInfoSlice = createSlice({
  name: "groupInfo",
  initialState: initialGroupInfoUpdateState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    groupInfoUpdated: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.group = data;
    },
  }
});

export const membersInfoSlice = createSlice({
  name: "membersInfo",
  initialState: initialMembersInfoState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    membersFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.members = data;
    },
    membersUpdated: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.members = data;
    },
  }
});

export const groupContributionsSlice = createSlice({
  name: "groupContributions",
  initialState: initialGroupContributionsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    groupContributionsDefaultsFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.defaults = data;
    },
    groupContributionsFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.contributions = data;
    },
    groupContributionsUpdated: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.contributions = data;
    },
  }
});

export const groupLoansSlice = createSlice({
  name: "groupLoans",
  initialState: initialGroupLoansState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    groupLoansDefaultsFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.defaults = data;
    },
    groupLoansFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loans = data;
    },
    groupLoansUpdated: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.loans = data;
    },
  }
});

export const groupAccountsSlice = createSlice({
  name: "groupAccounts",
  initialState: initialGroupAccountsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    groupAccountsDefaultsFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.defaults = data;
    },
    groupAccountsFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.accounts = data;
    },
    groupAccountsUpdated: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.accounts = data;
    },
  }
});

export const groupConfirmSlice = createSlice({
  name: "groupConfirm",
  initialState: initialGroupConfirmState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    groupSummaryDefaultsFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.defaults = data;
    },
  }
});

export const banksSlice = createSlice({
  name: "banks",
  initialState: initialBanksState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    banksFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.banks = data;
    },
  }
});

export const bankBranchesSlice = createSlice({
  name: "bankBranches",
  initialState: initialBankBranchesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    bankBranchesFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.branches = data;
    },
  }
});
